import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgMarkup from '../svg-markup/svg-markup';

// Custom components.
import Button from '../button/button';
import HamburgerAccordion from './hamburger-accordion';

// Styles.
import styles from './hamburger-menu.module.scss';

// Icons.
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Hamburger } from './assets/hamburger.svg';

export default class HamburgerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleClick() {
    this.setState({ open: !this.state.open }, () => {
      if (this.state.open) {
        // Disallow body scrolling when the menu is open.
        document.querySelector('body').classList.add('fixed');
        // Add a listener to handle closing the menu.
        document.addEventListener('click', this.handleClose);
      }
      else {
        document.querySelector('body').classList.remove('fixed');
        document.removeEventListener('click', this.handleClose);
      }
    });
  }

  /**
   * A utility for finding the closest parent element with a matching
   * selector.
   *
   * See: https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/
   *
   * @param {*} elem A DOM element
   * @param {*} selector A selector
   * @returns {*} The element if found, otherwise null.
   */
  getClosest(elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) {
        return elem;
      }
    }
    return null;
  }

  /**
   * Determines when it is appropriate to close the hamburger menu,
   * depending on where the user is clicking.
   *
   * @param {*} e Event object.
   * @returns {void}
   */
  handleClose(e) {
    const closestNav = this.getClosest(e.target, `.${styles.mobileNav}`);
    const closestLink = this.getClosest(e.target, `.${styles.subMenuLink}`);

    // If the click is not internal to the nav, close the menu.
    // If the click is on an HamburgerMenu Link, close the menu.
    if (!closestNav || closestLink) {
      this.setState({ open: false }, () => {
        this.closeMenu();
      });
    }
  }

  closeMenu() {
    this.setState({ open: false }, () => {
      document.querySelector('body').classList.remove('fixed');
      document.removeEventListener('click', this.handleClose);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClose);
  }

  openMenu() {
    return (
      <React.Fragment>
        <Button
          aria-expanded={true}
          aria-label="Close the main menu."
          color="blueDark"
          className={styles.menuToggle}
          onClick={this.closeMenu}
          borderStyle="square"
        >
          <Close fill="#fff" height="26" width="26" />
        </Button>
        <nav
          aria-label="Main menu"
          className={styles.mobileNav}
          ref={(menu) => (this.menu = menu)}
        >
          <ul className={styles.mobileNavList}>
            {this.props.data.map((item) => (
              <li key={item.title}>
                <HamburgerAccordion
                  className={styles.mobileDropdownButton}
                  color="blueDark"
                  links={item.left}
                  buttonContent={
                    <div className={styles.accordionButtonContent}>
                      {item.icon && (
                        <SvgMarkup
                          markup={item.icon}
                          className={styles.menuIcon}
                          width="21"
                          height="21"
                        />
                      )}
                      <span>{item.title}</span>
                      <Arrow className={styles.arrow} height="9" width="9" />
                    </div>
                  }
                  borderStyle="square"
                />
              </li>
            ))}
          </ul>
        </nav>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className={styles.mobileMenu}>
        {this.state.open ? (
          this.openMenu()
        ) : (
          <Button
            aria-expanded="false"
            aria-label="Open the primary navigation"
            color={false}
            className={styles.menuToggle}
            onClick={this.handleClick}
            borderStyle="square"
          >
            <Hamburger fill="#fff" height="26" width="33" />
          </Button>
        )}
      </div>
    );
  }
}

HamburgerMenu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    left: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
    })),
  })),
};
