import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import 'focus-visible';
import kebabcase from 'lodash/kebabCase';

// Emergency alert modal.
import Modal from 'react-modal';

// Global Styles.
import '../../global/fonts/fonts.scss';
import '../../global/global.scss';

import MegaMenuContext from '../mega-menu-context/mega-menu-context';
import { Provider } from '../search-context/search-context';

// Layout Styles.
import styles from './default-layout.module.scss';

// Nested components.
import Header from '../header/header';
import Footer from '../footer/footer';
import SEO from '../seo/seo';
import Button from '../button/button';
import Icon from '../icons/icon';
import WYSIWYG from '../wysiwyg/wysiwyg';

import modalstyles from '../search/search.module.scss';

const DefaultLayout = ({ children }) => {
  const classnames = {
    base: modalstyles.searchModal,
    afterOpen: modalstyles.afterOpen,
    beforeClose: modalstyles.beforeClose
  };

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          siteUrl
          spektrixClientName
          spektrixAPI
        }
      }
      siteSearchIndex {
        index
      }
      modalPage: nodePage(path: {alias: {eq: "/pop-notification"}}) {
        title
        body {
          processed
        }
      }
      MegaMenu: allTaxonomyTermPromotion(
        filter: {
          relationships: {
            parent: {
              elemMatch: {
                drupal_id: {
                  eq: "977ebf54-1cc8-4316-9fe6-e599100dc68e"
                }
              }
            },
            field_menu: {
              menu_name: {
                eq: "citysprings-main-menu"
              }
            }
          }
        }
      ) {
        edges {
          node {
            name
            relationships {
              field_menu {
                drupal_id
              }
              field_promoted_content {
                ...MegaMenuNews
                ...MegaMenuEvent
                ...MegaMenuPlace
                ...MegaMenuPage
              }
            }
          }
        }
      }
    }
  `);
  const [modalIsOpen, openModal] = useState(null);

  const triggerClose = () => {
    // If the alert is shown once, hide it.
    if (data.modalPage) {
      // Create a unique key for the local storage data based on the page title.
      const hideAlert = `alert-${kebabcase(data.modalPage.title)}`;
      // If the alert has already been shown, hide it.
      if (localStorage.getItem(hideAlert)) {
        openModal(false);
      }
      // Else show it.
      else {
        openModal(true);
        localStorage.setItem(hideAlert, true);
      }
    }
  };

  useEffect(() => {
    triggerClose();
  }, []);

  return (
    <MegaMenuContext.Provider value={data.MegaMenu && data.MegaMenu.edges}>
      <Provider value={data.siteSearchIndex}>
        <div className={styles.siteBackground}>
          {/* Include SEO defaults, they will likely be overriden at the template
        or page level. */}
          <SEO siteConfig={data.site.siteMetadata} />
          <div className={styles.siteWrapper}>
            <a className="visually-hidden focusable" href="#main-content">
              Skip to main content
            </a>
            {data.modalPage && <Modal
              className={classnames}
              closeTimeoutMS={100}
              contentLabel="Alert"
              isOpen={modalIsOpen}
              onRequestClose={triggerClose}
            >
              <div className={styles.alert}>
                <div className={styles.alertContainer}>
                  <h1 className={styles.alertHeading}>{data.modalPage.title}</h1>
                  <WYSIWYG>
                    <div
                      className={styles.body}
                      dangerouslySetInnerHTML={{ __html: data.modalPage.body.processed }}
                    />
                  </WYSIWYG>
                  <Button
                    aria-label="Close alert"
                    className={styles.alertButton}
                    onClick={triggerClose}
                  >
                    Don&apos;t Show Again
                  </Button>
                </div>
              </div>
            </Modal>}

            <Header siteTitle={data.site.siteMetadata.title} data={data} />
            <div id="main-content" className={styles.siteContainer}>
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </Provider>
    </MegaMenuContext.Provider>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};

export default DefaultLayout;
