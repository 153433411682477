import React, { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { useMainMenuData } from '../../hooks/use-main-menu-data';

// Custom compontents.
import { default as Dropdown } from './mega-menu-dropdown';
import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import MegaMenuContext from '../mega-menu-context/mega-menu-context';
import MenuPanel from '../menu-panel/menu-panel';
import { finitePromotionTypes, filterExpiredPromotions, get } from '../../helpers/hooks-helpers';

// Styles.
import styles from './mega-menu.module.scss';

/**
 * Component for rendering the MegaMenu.
 *
 * @returns {JSX} The menu JSX.
 */
export default function MegaMenu() {
  // The MegaMenu breaks to the HamburgerMenu at this breakpoint.
  const breakpoint = 1210;
  const [isMega, setIsMega] = useState(false);
  const drupalMenuData = useMainMenuData();

  // Takes all promoted events and returns one non-expired event.
  const removeExpiredContent = (content) => {
    // Return the first non-expired promoted content.
    const promotedContent = (content || []).find(item =>
      // eslint-disable-next-line no-underscore-dangle
      finitePromotionTypes.has(item.__typename) && filterExpiredPromotions(item)
    );
    return promotedContent || '';
  };

  // Displays the MegaMenu vs. the HamburgerMenu depending on viewport width.
  const handleResize = throttle(
    () => setIsMega(window.innerWidth > breakpoint),
    500
  );

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Remove body .fixed when resizing from small to large while
    // the hamburger menu is open.
    if (isMega) {
      document.querySelector('body').classList.remove('fixed');
    }
  }, [isMega]);

  return !isMega ? <HamburgerMenu data={drupalMenuData} /> : (
    <MegaMenuContext.Consumer>
      {(menuData) => (
        <nav aria-label="Main Menu" className={styles.megaMenu}>
          <ul className={styles.menuItems}>
            {drupalMenuData.map((panel, index, allPanels) => (
              <li key={get(panel, 'title', index)} className={styles.menuItem}>
                <Dropdown buttonContent={panel.title}>
                  <MenuPanel
                    left={panel.left}
                    right={
                      get(panel, 'id', null) === null ? [] : removeExpiredContent(
                        menuData.reduce((carry, { node }) => carry.concat(...(
                          get(node, 'relationships.field_menu.drupal_id') === panel.id
                            ? get(node, 'relationships.field_promoted_content', [])
                            : []
                        )),[])
                      )
                    }
                    panelDirection={((allPanels.length - 4) - index) > 0 ? '' : 'right'}
                  />
                </Dropdown>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </MegaMenuContext.Consumer>
  );
}
