import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import Button from '../button/button';
import Dropdown from '../dropdown/dropdown';
import Link from '../link/link';
import Search from '../search/search';

import styles from './header-top-menu.module.scss';
import { useTopMenuData } from '../../hooks/use-top-menu-data';

/**
* If a menu has children, render the menu with a dropdown,
* otherwise use a button/link.
*
* @param {object} menu A single menu object.
* @returns {JSX} The menu in JSX
*/
const renderSubmenu = (menu) => {
  // **2025** update
  // contact us menu item should be transparent.
  // on admin the color has been changed to none which gives a null value for parent color
  // logic was added to the Button color to check if isContactUs is true then use the menu.parent.color or white
  // otherwise use the transparent class in button.module.scss which has a transparent background
  // but uses the color styles for text color
  const isContactUs = menu.parent.title === 'Contact Us';
  if (menu.children && menu.children.length > 0) {
    return (
      <Dropdown
        borderStyle="square"
        textStyle="upper"
        className={styles.topMenuButton}
        buttonContent={menu.parent.title}
        color={menu.parent.color || 'blue'}
        id={`top-menu-${kebabCase(menu.parent.title)}`}
      >
        <ul className={styles.subMenu}>
          {menu.children.map((child, index) => (
            <li key={index} className={styles.subMenuItem}>
              <Link className={styles.subMenuLink} to={child.link}>
                {child.title}
              </Link>
            </li>
          ))}
        </ul>
      </Dropdown>
    );
  }

  return (
    <Button
      to={menu.parent.link}
      className={menu.parent.hideOnMobile ? styles.topLinks: styles.topMenuButton}
      color={!isContactUs ? menu.parent.color || 'white' : 'transparent'}
      borderStyle="square"
      textStyle="upper"
    >
      {menu.parent.title}
    </Button>
  );
};

/**
 * Loop through the items in menuItems and build
 * the menu markup / structure.
 *
 * @param {object} data Data object provided by the default-layout query.
 * @returns {JSX} The menu JSX.
 */
export default function HeaderTopMenu({ data }) {
  // 2025 Spektrix menu items My Account and Cart are going to be removed
  const menuItems = useTopMenuData();
  return (
    <nav className={styles.headerTopMenu}>
      <ul className={styles.headerTop}>
        {menuItems.map((menu, index) => (
          <li key={index} className={styles.menuItem}>
            {renderSubmenu(menu)}
          </li>
        ))}
        {/* <li className={styles.menuItem}>
          <spektrix-login-status
            client-name={data.site.siteMetadata.spektrixClientName}
            // Remove the https:// from the site url because custom domain only wants
            // the name.
            custom-domain={data.site.siteMetadata.spektrixAPI.replace('https://', '')}>
            <span data-logged-in-container style={{display: 'none'}}>
              <Button
                to='/account'
                className={styles.topLinks}
                color='white'
                borderStyle="square"
                textStyle="upper"
              >
                Hi, <span data-logged-in-status-customer-first-name></span>
              </Button>
            </span>
            <span data-logged-out-container>
              <Button
                to='/account'
                className={styles.topLinks}
                color='white'
                borderStyle="square"
                textStyle="upper"
              >
                My Account
              </Button>
            </span>
          </spektrix-login-status>
        </li>
        <li>
          <spektrix-basket-summary
            client-name={data.site.siteMetadata.spektrixClientName}
            custom-domain={data.site.siteMetadata.spektrixAPI.replace('https://', '')}>
            <Button
              to='/cart'
              className={styles.topLinks}
              color='white'
              borderStyle="square"
              textStyle="upper"
            >
            Cart (<span data-basket-item-count></span>)
            </Button>
          </spektrix-basket-summary>
        </li> */}
        <li className={styles.search}>
          <Search />
        </li>
      </ul>
    </nav>
  );
}

HeaderTopMenu.propTypes = {
  data: PropTypes.object
};
