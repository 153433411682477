import React from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'react-render-markup';
import classnames from 'classnames';
import styles from './svg-markup.module.scss';

/**
 * A component for rendering raw SVG markup.
 *
 * @returns {JSX} A SVG element.
 */
export default function SvgMarkup({ markup, className, ...props }) {
  // eslint-disable-next-line react/prop-types
  const Svg = ({ children, ...attributes }) =>
    <svg {...attributes} height="100%" preserveAspectRatio="none" {...props}>{children}</svg>;

  // Concatenates the appropriate classes.
  const classes = classnames(styles.svgContainer, className);
  return (
    <div className={classes}>
      <Markup replace={{ 'svg': Svg }} markup={markup} />
    </div>
  );
}

SvgMarkup.propTypes = {
  markup: PropTypes.string,
  className: PropTypes.string,
};
