import { useStaticQuery, graphql } from 'gatsby';
import { convertAbsoluteToRelative } from '../helpers/menu-helpers';
import { get } from 'lodash';

export const useMainMenuData = () => {
  const { allMenuLinkContentCityspringsMainMenu } = useStaticQuery(
    graphql`
      query MainMenuQuery {
        allMenuLinkContentCityspringsMainMenu(
          filter: { enabled: { eq: true } }
          sort: { order: ASC, fields: weight }
        ) {
          nodes {
            link {
              uri
            }
            title
            weight
            menu_name
            drupal_parent_menu_item
            drupal_id
            enabled
            field_icon {
              content
            }
          }
        }
      }
    `
  );

  const menuItems = {};
  for (const menuItem of allMenuLinkContentCityspringsMainMenu.nodes) {
    const key = (menuItem.drupal_parent_menu_item || menuItem.drupal_id).replace('menu_link_content:', '');
    menuItems[key] = menuItems[key] || { left: [] };
    if (menuItem.drupal_parent_menu_item) {
      menuItems[key].left.push({
        icon: menuItem.field_icon.content,
        text: menuItem.title,
        link: convertAbsoluteToRelative(menuItem.link.uri),
        weight: menuItem.weight || 0,
      });
    }
    else {
      menuItems[key].weight = menuItem.weight || 0;
      menuItems[key].title = menuItem.title;
      menuItems[key].icon =  menuItem.field_icon.content;
      menuItems[key].id =  menuItem.drupal_id;
    }
  }

  const sortByWeight = (a, b) => {
    return get(a, 'weight', 0) < get(b, 'weight', 0) ? -1 : 1;
  };

  return Object.values(menuItems)
    .filter((menuItem) => menuItem.title)
    .map((menuItem) => {
      menuItem.left.sort(sortByWeight);
      return menuItem;
    })
    .sort(sortByWeight);
};
