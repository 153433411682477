import React from 'react';

import { ReactComponent as Facebook } from './assets/Facebook.svg';
import { ReactComponent as Instagram } from './assets/Instagram.svg';
import { ReactComponent as X } from './assets/X.svg';

import styles from './social-links.module.scss';

const SocialLinks = ({
  className,
  fill = '#03758a',
  width = 19,
  height = 19
}) => (
  <nav className={className} aria-label="Social Links">
    <ul className={styles.socialLinks}>
      <li className={styles.socialLinkItem}>
        <a
          className={styles.socialLink}
          href="https://x.com/citysprings"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on X. Link opens in a new window."
        >
          <X
            className={styles.socialLinkIcon}
            fill={fill}
            width={width}
            height={height}
            focusable="false"
          />
        </a>
      </li>
      <li className={styles.socialLinkItem}>
        <a
          className={styles.socialLink}
          href="https://www.facebook.com/CitySpringsDistrict/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on Facebook. Link opens in a new window."
        >
          <Facebook
            className={styles.socialLinkIcon}
            fill={fill}
            width={width}
            height={height}
            focusable="false"
          />
        </a>
      </li>
      <li className={styles.socialLinkItem}>
        <a
          className={styles.socialLink}
          href="https://www.instagram.com/cityspringsdistrict"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Find us on Instagram. Link opens in a new window."
        >
          <Instagram
            className={styles.socialLinkIcon}
            fill={fill}
            width={width}
            height={height}
            focusable="false"
          />
        </a>
      </li>
    </ul>
  </nav>
);

export default SocialLinks;
